<template>
  <div class="d-flex justify-center mt-8">
    <v-card
      ref="card"
      elevation="0"
      class="w-full rounded-xl"
      style="max-width: 600px"
    >
      <div style="position: relative">
        <v-img
          width="100%"
          class="rounded-t-xl rounded-b-0"
          :src="party?.cover"
          :aspect-ratio="16 / 9"
        >
          <template v-slot:placeholder>
            <v-card elevation="0" class="h-full">
              <v-skeleton-loader
                type="image"
                class="rounded-t-lg h-100"
                height="100%"
              />
            </v-card>
          </template>
        </v-img>
      </div>

      <v-card-text class="d-flex flex-column" style="gap: 0.5rem">
        <v-card outlined class="pa-4" rounded="lg">
          <div v-if="loading">
            <v-skeleton-loader
              height="20"
              width="160"
              type="heading"
              class="mb-1"
            />
            <v-skeleton-loader
              height="25"
              width="130"
              type="heading"
              class="mb-1"
            />
          </div>
          <div v-else @click="goToParty" style="cursor: pointer">
            <div class="d-flex justify-space-between">
              <p
                class="mb-0 text-overline font-weight-bold"
                style="line-height: 1em"
              >
                Pedido #{{ payment?.id | shortId }}
              </p>
              <v-chip
                class="flex-shrink-0"
                label
                x-small
                :color="ticketStatusLabel[payment.status].color"
              >
                <v-icon x-small left>
                  {{ ticketStatusLabel[payment.status].icon }}
                </v-icon>
                {{ ticketStatusLabel[payment.status].text }}
              </v-chip>
            </div>
            <h5>
              {{ party?.name }}
            </h5>
          </div>
          <v-skeleton-loader
            v-if="loading"
            height="18"
            width="175"
            type="heading"
            class="mb-0 mt-2"
          />
          <div v-else class="d-flex align-center text-body-2">
            <v-icon small left>mdi-calendar</v-icon>
            <span>{{
              party
                | startEndDate("date", "endDate", " - ", {
                  month: " [de] MMM",
                })
            }}</span>
          </div>

          <v-row dense v-if="loading" class="mx-0 mt-2">
            <v-col cols="6" v-for="i in 2" :key="i">
              <v-skeleton-loader
                type="image"
                class="rounded h-100"
                height="36"
              />
            </v-col>
          </v-row>
          <template v-if="payment">
            <v-btn
              v-if="isNotPayed(payment)"
              color="warning"
              @click="pay"
              block
              class="mt-4"
              :disabled="loading"
            >
              Pagar
            </v-btn>
            <v-btn
              v-if="isNotPayed(payment)"
              color="secondary"
              text
              @click="cancelPayment"
              block
              small
              class="mt-1"
              :disabled="loading"
            >
              Cancelar
            </v-btn>

            <v-btn
              v-else-if="payment.status !== 'succeeded'"
              color="info"
              :to="{
                name: 'shop.party',
                params: {
                  orgSlug: party?.Organization?.slug,
                  partyId: party?.id,
                },
              }"
              block
              class="mt-4"
              :disabled="loading"
            >
              Iniciar nova compra
            </v-btn>
          </template>
        </v-card>

        <v-alert v-if="!loading && isNotPayed(payment)" dark color="black">
          <div class="d-flex align-center justify-space-between gap-2">
            <div class="text-14 font-weight-medium">
              <v-icon small left color="warning">mdi-alert</v-icon>
              Restam
              <span class="text-15 lh-1 font-weight-black">
                {{ remainingTime }}
              </span>
              para finalizar o pagamento e garantir
              {{
                tickets.length === 1
                  ? "o seu ingresso"
                  : `seus ${tickets.length} ingressos`
              }}{{
                productOrder && productOrder.Items.length > 0
                  ? " e produtos"
                  : ""
              }}. Após esse tempo, a reserva será cancelada automaticamente.
            </div>
          </div>
        </v-alert>

        <div v-if="!loading" class="d-flex flex-column gap-4">
          <!-- Ingressos -->
          <div class="d-flex flex-column gap-1">
            <p class="mb-0 text-overline font-weight-bold">Ingressos</p>
            <v-alert
              v-for="(ticket, i) of tickets"
              :key="i"
              :border="
                ticket.TicketEntry || ticket.TicketTransfer ? undefined : 'left'
              "
              text
              :color="
                ticket.TicketTransfer || isNotPayed(payment)
                  ? 'secondary'
                  : ticketStatusLabel[payment.status].color
              "
              :style="ticket.TicketTransfer ? '' : 'cursor: pointer'"
              v-ripple="!ticket.TicketTransfer"
              class="mb-0 pr-1 mx-2"
              @click="
                ticket.TicketTransfer || payment.status !== 'succeeded'
                  ? undefined
                  : goToTicket(ticket)
              "
              dense
              contentClass="pr-0"
            >
              <div class="d-flex align-center gap-2">
                <b v-if="tickets.length > 1">{{ i + 1 }}.</b>
                <b class="mb-0 text-14 font-weight-medium">
                  {{ ticket.TicketBlock.TicketGroup.name }} •
                  {{ ticket.TicketBlock.name }}
                </b>
                <v-chip v-if="ticket.TicketEntry" label x-small class="ml-1">
                  Usado
                </v-chip>
                <v-spacer />
                <v-btn
                  x-small
                  depressed
                  class="mr-2"
                  v-if="payment.status === 'succeeded'"
                  :color="
                    ticket.TicketTransfer
                      ? 'secondary'
                      : ticketStatusLabel[payment.status].color
                  "
                >
                  Ver Ingresso
                </v-btn>
                <v-chip
                  v-else-if="ticket.TicketTransfer"
                  color="secondary"
                  label
                  small
                >
                  Transferido
                </v-chip>
              </div>
            </v-alert>
            <v-alert
              v-if="isNotPayed(payment)"
              dense
              text
              class="mb-0 mx-2 mt-2"
              icon="mdi-information"
            >
              {{
                tickets.length === 1
                  ? "O ingresso será liberado após a confirmação do pagamento."
                  : "Os ingressos serão liberados após a confirmação do pagamento."
              }}
            </v-alert>
          </div>

          <!-- Produtos -->
          <div v-if="productOrder">
            <p class="mb-0 text-overline font-weight-bold">Produtos</p>
            <v-row dense class="mx-1">
              <v-col
                v-for="item in productOrder.Items"
                :key="item.id"
                cols="4"
                sm="3"
                style="position: relative"
              >
                <div>
                  <v-img
                    :src="
                      item?.Variant?.Images[0]?.url ||
                      item?.Variant?.Product?.image
                    "
                    :aspect-ratio="1"
                    class="rounded-lg"
                  />
                  <v-chip style="position: absolute; top: 0; right: 0">
                    x{{ item.quantity }}
                  </v-chip>
                </div>
              </v-col>
              <v-col cols="12" v-if="isNotPayed(payment)">
                <v-alert dense text class="mb-0 mt-1" icon="mdi-information">
                  Os produtos adquiridos nesse pedido serão retirados no evento.
                </v-alert>
              </v-col>
              <v-col v-else-if="payment.status === 'succeeded'" cols="12">
                <v-btn
                  text
                  block
                  color="primary"
                  :to="{
                    name: 'app.products.details',
                    params: { id: productOrder.id },
                  }"
                >
                  Ver Produtos
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <!-- SubTotal -->
          <div
            v-if="payment.paymentMethod != 'COURTESY'"
            class="d-flex flex-column gap-1 px-2"
          >
            <div class="d-flex justify-space-between align-center">
              <span class="text-14 text--disabled font-weight-medium">
                Subtotal
              </span>
              <span class="text-14 text--disabled font-weight-medium text-end">
                {{ (payment?.amount + payment?.discount) | currency(true) }}
              </span>
            </div>
            <div class="d-flex justify-space-between align-center">
              <span class="text-14 text--secondary font-weight-medium">
                Desconto
              </span>
              <span class="text-14 text--secondary font-weight-bold text-end">
                {{ ((payment?.discount || 0) * -1) | currency(true) }}
              </span>
            </div>
            <div class="d-flex justify-space-between align-center">
              <span class="text-14 font-weight-bold">Total</span>
              <span class="text-16 font-weight-bold text-end">
                {{ payment?.amount | currency(true) }}
              </span>
            </div>
          </div>

          <!-- Detalhes -->
          <div
            v-if="payment"
            class="d-flex flex-wrap gap-5 justify-space-around px-8 mt-6"
          >
            <div class="text-center px-2">
              <p class="text-14 mb-0 text--secondary font-weight-bold">
                {{
                  paymentMethod[payment.paymentMethod]?.text ||
                  payment.paymentMethod
                }}
              </p>
              <span class="text-14 text--secondary font-weight-medium">
                Meio de Pagamento
              </span>
            </div>
            <div class="text-center px-2">
              <p class="text-14 mb-0 text--secondary font-weight-bold">
                {{
                  paymentType[payment.paymentType]?.text || payment.paymentType
                }}
              </p>
              <span class="text-14 text--secondary font-weight-medium">
                Método de Pagamento
              </span>
            </div>
            <div class="text-center px-2">
              <p
                v-if="!payment.payedAt"
                class="text-14 mb-0 text--secondary font-weight-bold"
              >
                Não Pago
              </p>
              <p v-else class="text-14 mb-0 text--secondary font-weight-bold">
                {{ payment.payedAt | date("DD/MM/YYYY HH:mm") }}
              </p>
              <span class="text-14 text--secondary font-weight-medium">
                Data do pagamento
              </span>
            </div>
            <div class="text-center px-2">
              <p class="text-14 mb-0 text--secondary font-weight-bold">
                {{ payment.createdAt | date("DD/MM/YYYY HH:mm") }}
              </p>
              <span class="text-14 text--secondary font-weight-medium">
                Criação do Pagamento
              </span>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <ticket-payment
      v-if="tickets"
      ref="payment"
      :ticket-id="tickets[0].id"
      :Tickets="tickets"
      :party="party"
      @refresh="getPayment"
    />
    <ticket-refound />
    <cancel-my-payment
      ref="cancelMyPayment"
      :payment="payment"
      :tickets="tickets"
      :party="party"
    />
  </div>
</template>

<script>
import moment from "moment";
import PAYMENT from "@/services/app/payment";
import Payment from "@/utils/payment";

import { process } from "@/utils/shop/cart";
import TicketPayment from "./TicketPayment.vue";
import TicketRefound from "@/components/app/modals/TicketRefound.vue";
import PaymentStatus from "../../../components/global/PaymentStatus.vue";
import CancelMyPayment from "../../../components/app/modals/CancelMyPayment.vue";

export default {
  metaInfo() {
    return {
      title:
        "Pagamento " +
          (this.payment &&
            `#${this.$options.filters.shortId(this.payment?.id || "")} - `) ||
        "" + (this.party?.name || ""),
    };
  },
  components: {
    TicketPayment,
    PaymentStatus,
    TicketRefound,
    CancelMyPayment,
  },
  data: () => ({
    payment: null,
    loading: true,
    tickets: null,
    productOrder: null,
    paymentType: Payment.paymentType,
    paymentMethod: Payment.paymentMethod,
    ticketStatusLabel: Payment.ticketStatus,
    ticketStatusColor: {
      valid: "success",
      invalid: "error",
      pending: "warning",
    },
    interval: null,
    remainingTime: null,
  }),
  methods: {
    goToTicket({ id }) {
      this.$router.push({
        name: "app.ticket.details",
        params: { id: id },
      });
    },
    goToParty() {
      this.$router.push({
        name: "shop.party",
        params: {
          orgSlug: this.party.Organization.slug,
          partyId: this.party.slug || this.party.id,
        },
      });
    },
    pay() {
      this.$refs.payment.open();
    },
    cancelPayment() {
      this.$refs.cancelMyPayment.open(this.payment);
    },
    refound() {
      this.$emit("refound-ticket", this.ticket);
    },
    isNotPayed(payment) {
      if (!payment) return false;
      return [
        "pending",
        "requires_payment_method",
        "requires_confirmation",
        "requires_action",
        "processing",
        "requires_capture",
        "rejected",
      ].includes(payment?.status);
    },
    async getPayment(updating = false) {
      try {
        if (!updating) this.loading = true;
        const paymentId = this.$route.params.id;
        const response = await PAYMENT.getById(paymentId);

        this.tickets = response.tickets.sort((a, b) => {
          if (a.TicketTransfer === b.TicketTransfer) return 0;
          if (a.TicketTranfer) return 1;
          return -1;
        });
        this.productOrder = response.productOrder;
        this.payment = response.payment;

        if (!updating) this.paymentValidityClock(this.payment);

        this.loading = false;
        return response.payment;
      } catch (error) {
        console.log(error);
        this.$router.push({ name: "app.ticket" });
      }
    },
    paymentValidityClock(payment) {
      if (this.isNotPayed(payment)) {
        this.updateRemainingTime();
        const validity = moment(payment.validity);
        clearInterval(this.interval);
        this.interval = setInterval(
          this.updateRemainingTime,
          validity.diff(moment()) > 1000 ? 1000 : validity.diff(moment())
        );
      }
    },
    updateRemainingTime() {
      const now = moment();
      const validity = moment(this.payment.validity);
      const diff = validity.diff(now);

      const roundMinute = Math.round(diff / 1000) % 20;
      if (!roundMinute) this.getPayment(true);
      if (diff <= 0) {
        clearInterval(this.interval);
        this.$refs.cancelMyPayment.cancelPayment();
        return;
      }
      this.remainingTime = moment.utc(diff).format("mm:ss");
    },
  },
  computed: {
    cartProcessed() {
      return process(this.cart);
    },
    party() {
      const [ticket] = this.tickets || [];
      return ticket?.TicketBlock?.TicketGroup?.Party;
    },
    cart() {
      if (!this.tickets) return {};
      return this.tickets.reduce((acc, ticket) => {
        const ticketBlockId = ticket.TicketBlock.id;
        if (!acc[ticketBlockId])
          acc[ticketBlockId] = { quantity: 0, ticketBlock: ticket.TicketBlock };
        acc[ticketBlockId].quantity += 1;
        return acc;
      }, {});
    },
  },
  async mounted() {
    const payment = await this.getPayment();

    if (this.$route.hash == "#pay" && this.isNotPayed(payment)) {
      this.$router.replace({
        name: "app.payment.details",
        params: { id: this.payment.id },
      });
      this.pay();
      return;
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
