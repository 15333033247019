<template>
  <div class="text-center px-6 py-2">
    <template v-if="!success">
      <v-form @submit.stop v-model="valid">
        <v-row no-gutters class="px-3 pt-2 mb-2">
          <v-col cols="12">
            <div style="position: relative">
              <v-text-field-simplemask
                v-model="card.number"
                label="Número do Cartão"
                v-bind:properties="{
                  rules: cardRules,
                  autocomplete: 'cc-number',
                  disabled: loading,
                  outlined: true,
                  dense: true,
                  placeholder: '0000 0000 0000 0000',
                }"
                v-bind:options="{
                  inputMask: '#### #### #### #### ####',
                  outputMask: '####################',
                  empty: null,
                  applyAfter: false,
                  alphanumeric: false,
                  lowerCase: false,
                }"
              >
              </v-text-field-simplemask>
              <div
                class="d-flex align-center justify-center"
                style="
                  position: absolute;
                  height: 40px;
                  width: 52px;
                  right: 0px;
                  top: 0px;
                "
              >
                <v-img :src="cardIcon" contain height="26" width="100%" />
              </div>
            </div>
          </v-col>
          <v-col cols="5" class="pr-2">
            <v-text-field-simplemask
              v-model="card.expiration"
              label="Vencimento"
              v-bind:properties="{
                rules: cartExpirationRules,
                autocomplete: 'cc-exp',
                disabled: loading,
                outlined: true,
                dense: true,
                hint: 'MM/AA',
                placeholder: 'MM/AA',
              }"
              v-bind:options="{
                inputMask: '##/##',
                outputMask: '##/##',
                empty: null,
                applyAfter: false,
                alphanumeric: false,
                lowerCase: false,
              }"
            />
          </v-col>
          <v-col cols="7">
            <v-text-field
              v-model="card.security_code"
              :disabled="loading"
              :label="`Código de Segurança (${cardType?.code?.name || 'CVV'})`"
              :rules="[
                (v) => !!v || 'O campo é obrigatório',
                (v) =>
                  (v && v.length === (cardType?.code?.size || 3)) ||
                  `O campo deve ter ${cardType?.code?.size || 3} digitos`,
              ]"
              :placeholder="cardType?.code?.size === 4 ? '0000' : '000'"
              autocomplete="cc-csc"
              outlined
              dense
              @focus="focusSecurityCode = true"
              @blur="focusSecurityCode = false"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="card.holder.name"
              label="Nome do Titular"
              :rules="[(v) => !!v || 'O campo é obrigatório']"
              autocomplete="cc-name"
              :disabled="loading"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field-simplemask
              v-model="card.holder.tax_id"
              label="CPF"
              v-bind:properties="{
                prefix: '',
                suffix: '',
                outlined: true,
                placeholder: '999.999.999-99',
                type: 'tel',
                rules: cpfRules,
                disabled: loading,
                dense: true,
              }"
              v-bind:options="{
                inputMask: '###.###.###-##',
                outputMask: '###.###.###-##',
                empty: '',
                applyAfter: false,
                alphanumeric: false,
                lowerCase: false,
              }"
            />
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="card.installments"
              :items="installments"
              :label="interestLabel"
              :rules="[(v) => !!v || 'O campo é obrigatório']"
              outlined
              dense
              :disabled="loading"
            >
              <template v-slot:selection="{ item }">
                {{ item.text }} {{ item.interestValue | currency }}
                {{ item.interestFree ? "sem juros" : "" }}
              </template>
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item
                  style="min-height: 35px"
                  v-bind="attrs"
                  v-on="on"
                  class="d-flex align-center"
                >
                  <span class="font-weight-medium">
                    {{ item.text }} {{ item.interestValue | currency(true) }}
                  </span>
                  <v-spacer />
                  <small
                    class="text--secondary"
                    v-if="item.interestValue !== item.price"
                  >
                    {{ item.price | currency }}
                  </small>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-form>
      <v-alert
        v-if="error"
        type="error"
        dense
        text
        transition="scale-transition"
      >
        {{ error }}
      </v-alert>
      <v-btn
        color="primary"
        @click="pay"
        :loading="loading"
        block
        :disabled="!valid"
      >
        Pagar • {{ card.installments }}x de
        {{
          installments.find((i) => i.value === card.installments).interestValue
            | currency
        }}
      </v-btn>
    </template>
    <v-fab-transition v-else>
      <div class="pa-4">
        <v-icon
          size="60"
          :color="success === 'succeeded' ? 'success' : 'warning'"
        >
          {{
            success === "succeeded"
              ? "mdi-check-circle"
              : "mdi-credit-card-clock"
          }}
        </v-icon>

        <h4 class="mt-2">
          {{
            success === "succeeded"
              ? "Pagamento Aprovado"
              : "Pagamento em Análise"
          }}
        </h4>
      </div>
    </v-fab-transition>

    <div class="d-flex justify-center mt-4">
      <v-btn text @click="close" :disabled="loading" small> Fechar </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import validateCpf from "@/utils/validate-cpf";
import creditCardType from "credit-card-type";

import TICKET from "@/services/app/ticket";

import QrcodeVue from "qrcode.vue";

const icons = require.context("@/assets/images/payment-card/", false, /\.svg$/);

export default {
  components: { QrcodeVue },
  data: () => ({
    icons,
    loading: false,
    error: null,
    valid: false,
    focusSecurityCode: false,
    success: false,
    card: {
      number: null,
      expiration: null,
      security_code: null,
      installments: 1,
      holder: {
        name: null,
        tax_id: null,
      },
    },
    cardErrosDictionary: {
      INVALID_NUMBER: "Número do cartão inválido",
      INVALID_SECURITY_CODE: "Código de segurança inválido",
      INVALID_EXPIRATION_MONTH: "Mês de vencimento inválido",
      INVALID_EXPIRATION_YEAR: "Ano de vencimento inválido",
      INVALID_PUBLIC_KEY: "Chave pública inválida",
      INVALID_HOLDER: "Nome do titular inválido",
    },
    cpfRules: [
      (v) => !!v || "CPF é obrigatório",
      (v) => validateCpf(v) || "CPF inválido",
    ],
  }),
  methods: {
    async pay() {
      try {
        this.error = null;
        this.loading = true;
        const expiration = moment(this.card.expiration, "MM/YY");

        const card = PagSeguro.encryptCard({
          publicKey: this.integrationData.publicKey,
          holder: this.card.holder.name,
          number: this.card.number,
          expMonth: expiration.format("MM"),
          expYear: expiration.format("YYYY"),
          securityCode: this.card.security_code,
        });

        if (card.hasErrors) {
          const errors = card.errors;
          if (errors.length > 0)
            throw {
              message:
                this.cardErrosDictionary[errors[0].code] ||
                "Erro ao processar pagamento, tente novamente",
            };
        }

        const encrypted = card.encryptedCard;

        const { success, data } = await TICKET.pay(this.ticketId, {
          type: "CREDIT_CARD",
          card: {
            encrypted,
            installments: this.card.installments,
            bin: this.card.number.substring(0, 6),
            last4: this.card.number.substring(this.card.number.length - 4),
          },
        });

        if (!success) throw new Error(data.message);

        this.success = data.status;

        this.$emit("lock", "CREDIT_CARD");
        this.$emit("refresh");
        this.loading = false;
        if (this.success === "succeeded") {
          this.$root.$emit("purchase", {
            amount: this.payment.amount,
            Tickets: this.tickets,
          });

          this.$confetti.start();
          await new Promise((r) => setTimeout(r, 5000));
          this.$confetti.stop();
        }
      } catch (error) {
        this.$emit("update");
        this.error =
          error.message || "Erro ao processar pagamento, tente novamente";
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    installments() {
      return this.payment.fees.map((fee) => {
        return {
          text: `${fee.installment}x`,
          value: fee.installment,
          interestFree: fee.interestFree,
          interestValue: fee.value,
          price: fee.total,
        };
      });
    },
    interestLabel() {
      const maxFreeInstallments = this.installments.reduce(
        (acc, curr) => (curr.interestFree ? curr.value : acc),
        1
      );
      return `Parcelamento em até ${maxFreeInstallments}x sem juros`;
    },
    cardType() {
      const creditCard = creditCardType(this.card.number);
      if (!creditCard || (!!creditCard && creditCard?.length != 1)) return null;

      return creditCard[0];
    },
    cardIcon() {
      try {
        const type = this.cardType.type;
        return this.icons(`./${type}.svg`);
      } catch (error) {
        return this.icons(`./default.svg`);
      }
    },
    cardRules() {
      const cardType = this.cardType;
      return [
        (v) => !!v || "O campo é obrigatório",
        (v) =>
          (v &&
            (cardType.lengths || []).includes(v.replace(/\D/g, "").length)) ||
          `Número inválido`,
      ];
    },
    cartExpirationRules() {
      return [
        (v) => !!v || "O validade é obrigatória",
        (v) => (v && v.length === 5) || "Data inválida",
        (v) => {
          const expiration = moment(v, "MM/YY");
          return (
            (expiration.isValid() &&
              expiration.isAfter(moment()) &&
              expiration.isBefore(moment().add(10, "years"))) ||
            "Data inválida"
          );
        },
      ];
    },
  },
  watch: {
    "card.expiration": {
      handler: function (value) {
        if (!value) return;

        if (value.length === 1 && value > 1)
          this.card.expiration = `0${value}/`;

        if (value.length === 2 && value > 12) this.card.expiration = "12/";
      },
      immediate: true,
    },
    "card.holder.name": {
      handler: function (value) {
        if (!value) return;
        this.card.holder.name = value.toUpperCase();
      },
      immediate: true,
    },
  },

  mounted() {
    if (this.payment.paymentType !== "CREDIT_CARD") return;
    if (
      ["succeeded", "requires_capture", "processing"].includes(
        this.payment.status
      )
    ) {
      this.success = this.payment.status;
    }
  },

  props: {
    ticketId: {
      type: String,
      required: true,
    },
    payment: {
      type: Object,
      required: true,
    },
    tickets: {
      type: Array,
      required: true,
    },
    integrationData: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
